<template>
  <div id="Manage">
    <!-- 基础设置 卡券管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="
        dialogFonfig.openType === 'add'
          ? dialogFonfig.addName
          : dialogFonfig.editName
      "
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button>取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加房型主题
          </el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchTopic(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
            <div class="line">—</div>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click="clickDeleteData(scope.$index, scope.row)">删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { Add_PUTroomTopic, GetroomTopic } from '@/api/basis.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {

      searchData: '', //存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增房型主题',
        editName: '编辑房型主题',
        isShowDialogVisible: false
      },
      edit_id: null,
      // form表单 基础数据
      openForm: {
        id: '',
        name: '',
      },
      openFormLabel: [

        {
          model: 'name',
          label: '房型主题名称'
        },
      ],

      // form表单 搜索数据
      searchForm: {
        id: '',
        name: '',
      },
      searchformLabel: [
        {
          model: 'id',
          label: '房型主题ID',
          width: '300px'
        },
        {
          model: 'name',
          label: '房型主题名称',
          width: '300px'
        },

      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '房型ID'
        },

        {
          prop: 'name',
          label: '房型名称'
        },
        {
          prop: 'create_time',
          label: '创建时间'
        },
        {
          prop: 'update_time',
          label: '更新时间'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


    }
  },
  created () {
    this.GetroomTopic()
  },
  mounted () {
  },
  beforeUpdate () { },
  watch: {

  },
  methods: {
    //搜索
    searchTopic (searchForm) {
      console.log(searchForm);
      GetroomTopic(searchForm).then(res => {
        this.tableData = res.data.data.list
        console.log(res);
      })
    },
    // 获取主题
    GetroomTopic (data) {
      GetroomTopic(data).then(res => {
        this.tableConfig.total = res.data.data.total
        this.tableData = res.data.data.list
        console.log(res, "获取主题");

      })
    },
    getPage (data) {
      console.log(data);
      // this.GetroomTopic()
      // console.log(this.searchData);
      if (data) {
        const DATA = {
          page: data
        }
        console.log(DATA, "DATA");
        this.GetroomTopic(DATA)
      } else {
        this.GetroomTopic()
      }
    },
    // 删除
    async clickDeleteData (idx, row) {
      console.log(idx);
      console.log(row, "====row=====");
      let data = {
        id: row.id
      }
      await Add_PUTroomTopic("DELETE", data)
      this.GetroomTopic()
    },
    //编辑
    clickEditData (idx, row) {
      this.edit_id = row.id
      console.log(idx);
      console.log(row, "====row=====");
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    clickCancelData () {

    },
    //新增或者编辑
    clickAddData () {
      this.dialogFonfig.isShowDialogVisible = true
    },
    resetFilter (searchForm) {
      searchForm.id = '';
      searchForm.name = '';
      searchForm.minArea = ''
      searchForm.maxArea = ''
      this.searchData = ""
      this.GetroomTopic()
    },
    async clickSubmitData (type, data) {
      console.log(type);
      console.log(data, "=====data====");
      // 新增
      if (type == 'add') {
        const DATA = {
          name: data.name

        }
        await Add_PUTroomTopic("POST", DATA)
        this.GetroomTopic()
        // 编辑
      } else if (type == 'edit') {
        const DATA = {
          name: data.name,
          id: this.edit_id
        }
        await Add_PUTroomTopic("PUT", DATA)
        this.GetroomTopic()

      }
      this.openForm = this.$options.data().openForm;
      this.dialogFonfig.isShowDialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;

  .el-transfer {
    text-align: center;

    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }

  .filtrateInput {
    background: #f8f7f7;
    width: 69%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
    position: relative;

    .line {
      position: absolute;
      top: -49px;
      left: 692px;
      color: #d8d8d8;
    }
  }

  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }

  /deep/ .el-dialog {
    width: 30%;
  }
}
</style>
